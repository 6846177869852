import React from 'react';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';

interface ITireFittingWheelComponentInterface {
  disk_location: 'front_left' | 'front_right' | 'rear_left' | 'rear_right' | 'front_axle' | 'rear_axle' | 'all';
  isLast: boolean;
}

export const TireFittingWheelComponent: React.FC<ITireFittingWheelComponentInterface> = ({ disk_location, isLast }) => {
  const getWheelTitle = () => {
    if (disk_location === 'front_left') {
      return 'Переднее левое';
    } else if (disk_location === 'front_right') {
      return 'Переднее правое';
    } else if (disk_location === 'rear_left') {
      return 'Заднее левое';
    } else if (disk_location === 'rear_right') {
      return 'Заднее правое';
    } else if (disk_location === 'front_axle') {
      return 'Передняя ось';
    } else if (disk_location === 'rear_axle') {
      return 'Задняя ось';
    } else if (disk_location === 'all') {
      return 'Все колеса';
    }
  };

  return (
    <Box sx={isLast ? { pb: 8 } : {}}>
      <Typography sx={{ textAlign: 'center', marginBottom: 1 }}>{getWheelTitle()}</Typography>
      <Autocomplete
        id="howWheelsMounted"
        size="small"
        fullWidth
        sx={{ marginBottom: 2 }}
        disableClearable={true}
        onChange={(event, newValue) => {
          console.log(newValue);
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        renderInput={(params) => <TextField {...params} label={'Радиус'} />}
        options={[
          { id: 'all_wheels_have_the_same_rubber', label: 'На всех колесах одна резина' },
          { id: 'front_and_read_are_different', label: 'Разная для передней и задней оси' },
          { id: 'everything_is_different', label: 'Все разные' },
        ]}
      />
      <Autocomplete
        id="howWheelsMounted"
        size="small"
        fullWidth
        sx={{ marginBottom: 2 }}
        disableClearable={true}
        onChange={(event, newValue) => {
          console.log(newValue);
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        renderInput={(params) => <TextField {...params} label={'Размер'} />}
        options={[
          { id: 'all_wheels_have_the_same_rubber', label: 'На всех колесах одна резина' },
          { id: 'front_and_read_are_different', label: 'Разная для передней и задней оси' },
          { id: 'everything_is_different', label: 'Все разные' },
        ]}
      />
      <Autocomplete
        id="howWheelsMounted"
        size="small"
        fullWidth
        sx={{ marginBottom: 2 }}
        disableClearable={true}
        onChange={(event, newValue) => {
          console.log(newValue);
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        renderInput={(params) => <TextField {...params} label={'Сезон'} />}
        options={[
          { id: 'all_wheels_have_the_same_rubber', label: 'На всех колесах одна резина' },
          { id: 'front_and_read_are_different', label: 'Разная для передней и задней оси' },
          { id: 'everything_is_different', label: 'Все разные' },
        ]}
      />
      <Autocomplete
        id="howWheelsMounted"
        size="small"
        fullWidth
        sx={{ marginBottom: 2 }}
        disableClearable={true}
        onChange={(event, newValue) => {
          console.log(newValue);
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        renderInput={(params) => <TextField {...params} label={'Марка'} />}
        options={[
          { id: 'all_wheels_have_the_same_rubber', label: 'На всех колесах одна резина' },
          { id: 'front_and_read_are_different', label: 'Разная для передней и задней оси' },
          { id: 'everything_is_different', label: 'Все разные' },
        ]}
      />
      <Autocomplete
        id="howWheelsMounted"
        size="small"
        fullWidth
        sx={{ marginBottom: 2 }}
        disableClearable={true}
        onChange={(event, newValue) => {
          console.log(newValue);
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        renderInput={(params) => <TextField {...params} label={'Модель'} />}
        options={[
          { id: 'all_wheels_have_the_same_rubber', label: 'На всех колесах одна резина' },
          { id: 'front_and_read_are_different', label: 'Разная для передней и задней оси' },
          { id: 'everything_is_different', label: 'Все разные' },
        ]}
      />
      <Autocomplete
        id="howWheelsMounted"
        size="small"
        fullWidth
        sx={{ marginBottom: 2 }}
        disableClearable={true}
        onChange={(event, newValue) => {
          console.log(newValue);
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        renderInput={(params) => <TextField {...params} label={'Тип диска'} />}
        options={[
          { id: 'molten_disk', label: 'Литой' },
          { id: 'stamped_disk', label: 'Штамп' },
        ]}
      />
    </Box>
  );
};

import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Fab,
  IconButton,
  ImageList,
  ImageListItem,
  Typography,
} from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { IOpenTask, IResponseNavigation, TPhoto } from '../../screens/types';
import { LuCamera, LuCheckCheck, LuChevronsDown, LuRefreshCcw, LuTrash } from 'react-icons/lu';
import { toast } from 'react-toastify';
import { usePostDeleteImageMutation, usePostUploadImageMutation } from '../../redux/services/tasks';
import { MdRemoveDone } from 'react-icons/md';
import imageCompression from 'browser-image-compression';
import { useGeolocated } from 'react-geolocated';

interface ICleaningElement {
  stageName: string;
  stageNumber: string;
  maxImageCount: number;
  afterCleaningPhotoArray: TPhoto[];
  pickedImage: string[];
  setPickedImage: (e: React.MouseEvent<HTMLImageElement>) => void;
  id?: string;
  task: IOpenTask;
  activeStep: number;
  navigation: IResponseNavigation;
  refetchTask: () => {};
  setImageLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CleaningElement: React.FC<ICleaningElement> = ({
  stageName,
  stageNumber,
  maxImageCount,
  afterCleaningPhotoArray,
  setPickedImage,
  pickedImage,
  id,
  task,
  activeStep,
  navigation,
  setImageLoading,
  refetchTask,
}) => {
  const [postUploadImage, postUploadImageResult] = usePostUploadImageMutation();
  const [imageList, setImageList] = React.useState<TPhoto[]>([]);
  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
  });

  React.useEffect(() => {
    setImageList(afterCleaningPhotoArray.filter((item) => item.name === stageName));
  }, [afterCleaningPhotoArray, stageName]);

  if (!isGeolocationEnabled) {
    return (
      <Box
        sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
      >
        <Typography sx={{ p: 3, textAlign: 'center' }}>
          Необходимо разрешить сайту доступ к геопозиции устройства. Без предоставления доступа у Вас нет возможности
          загружать фотографии в задачу. Позвольте данному сайту доступ к геопозиции в настройках вашего устройства.
        </Typography>
      </Box>
    );
  }

  if (!isGeolocationAvailable) {
    return (
      <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Typography sx={{ p: 3, textAlign: 'center' }}>
          На данном устройстве нельзя получить доступ к геопозиции. Без предоставления доступа у Вас нет возможности
          загружать фотографии в задачу.
        </Typography>
      </Box>
    );
  }

  const compressImage = async (image: File) => {
    const options = {
      fileType: 'image/jpeg',
      maxWidthOrHeight: 1280,
    };

    const compressedFile = await imageCompression(image, options);
    let compress_file = await compressedFile;
    return await compress_file;
  };

  const createImageFormData = async (image: File) => {
    if (!coords) return;
    if (!id) return;
    let datetime = new Date();
    const dt = new Date(datetime);
    const year = dt.getFullYear();
    const month = (dt.getMonth() + 1).toString().padStart(2, '0');
    const day = dt.getDate().toString().padStart(2, '0');
    const hour = dt.getHours().toString().padStart(2, '0');
    const minute = dt.getMinutes().toString().padStart(2, '0');
    const seconds = dt.getSeconds().toString().padStart(2, '0');
    const ms = dt.getMilliseconds().toString().padStart(2, '0');
    let formData = new FormData();
    const newName = id + '__' + `${day}-${month}-${year}__${hour}-${minute}-${seconds}-${ms}` + '.jpeg';
    const newFile = new File([image], newName);
    formData.append('task', id);
    formData.append('image', newFile);
    formData.append('name', stageName);
    formData.append('filename', newName);
    formData.append('photo_longitude', coords?.longitude.toFixed(6).toString());
    formData.append('photo_latitude', coords?.latitude.toFixed(6).toString());
    return formData;
  };

  const handleUploadImages = async (images?: FileList | null) => {
    if (!images) return;
    setImageLoading(true);
    for (let i = 0; i < images.length; i++) {
      let imageData = await compressImage(images[i]);
      let imageFormData = await createImageFormData(imageData);
      if (!imageFormData) return;
      await toast.promise(
        postUploadImage({ apiLink: navigation.stages[activeStep].api_link, formData: imageFormData }).unwrap(),
        {
          success: 'Фотография успешно загружена 👌',
          error: 'Ошибка при загрузке фотографии 🤯',
        },
      );
    }
    setImageLoading(false);
    refetchTask();
  };

  return (
    <Accordion>
      <AccordionSummary
        aria-controls={`panel${stageNumber}-content`}
        id={`panel${stageNumber}-header`}
        expandIcon={<LuChevronsDown />}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              <input
                accept="image/*"
                id={`raised-button-file-${stageNumber}`}
                type="file"
                multiple
                hidden
                onChange={(e) => {
                  const selectFile = e.target.files ? e.target.files : null;
                  handleUploadImages(selectFile);
                }}
              />
              <label
                htmlFor={`raised-button-file-${stageNumber}`}
                onClick={(e) => e.stopPropagation()}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <Button
                  disabled={imageList.length >= maxImageCount}
                  component="span"
                  color="primary"
                  sx={{ zIndex: 10000 }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <LuCamera size={20} />
                </Button>
              </label>
            </Box>
            <Typography component="span" sx={{ pl: 2 }}>
              {stageName}
            </Typography>
          </Box>
          <Typography component="span" sx={{ pr: 1, color: imageList.length < maxImageCount ? '#3a9561' : '#f50057' }}>
            {imageList.length}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ pl: 1, pr: 1 }}>
        <ImageList sx={{ mt: 0, mb: 0 }} component={'div'}>
          {imageList.length !== 0 &&
            imageList.map((item) => (
              <ImageListItem key={item.id} sx={{ aspectRatio: '1' }}>
                <img
                  id={item.id.toString()}
                  srcSet={`${item.image}`}
                  src={`${item.image}`}
                  alt={item.filename}
                  loading="lazy"
                  style={{ aspectRatio: 1 }}
                  onClick={(e) => setPickedImage(e)}
                />
                {pickedImage?.includes(item.id.toString()) && (
                  <Box sx={{ position: 'absolute', bottom: '5px', right: '5px' }}>
                    <LuCheckCheck color={'red'} size={20} />
                  </Box>
                )}
              </ImageListItem>
            ))}
        </ImageList>
      </AccordionDetails>
    </Accordion>
  );
};

interface IAfterCleaning {
  id?: string;
  task: IOpenTask;
  activeStep: number;
  navigation: IResponseNavigation;
  refetchTask: () => {};
  refetchNavigation: () => {};
  setImageLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AfterCleaning: React.FC<IAfterCleaning> = ({
  id,
  task,
  navigation,
  activeStep,
  refetchTask,
  refetchNavigation,
  setImageLoading,
}) => {
  const [pickedImage, setPickedImage] = React.useState<string[]>([]);
  const [postDeleteImage, postDeleteImageResult] = usePostDeleteImageMutation();
  const namesArray = [
    'Переднее сиденье',
    'Заднее сиденье',
    'Багажник',
    'Полка багажника',
    'Дверь',
    'Торпедо',
    'Пол',
    'Потолок',
    'Кузов',
  ];
  const maxImagesCountArray = [4, 2, 1, 1, 4, 1, 4, 2, 15];

  const onPickImage = (e: React.MouseEvent<HTMLImageElement>) => {
    setPickedImage((oldArray) => {
      let newArray = [...oldArray];
      if (newArray.includes((e.target as HTMLImageElement).id)) {
        const index = newArray.indexOf((e.target as HTMLImageElement).id);
        if (index !== -1) {
          newArray.splice(index, 1);
        }
        return [...newArray];
      } else {
        return [...oldArray, (e.target as HTMLImageElement).id];
      }
    });
  };

  const handleDeleteImages = async () => {
    toast
      .promise(postDeleteImage({ ids: pickedImage, apiLink: navigation.stages[activeStep].api_link }).unwrap(), {
        success: 'Фотография успешно удалена 👌',
        error: 'Ошибка при удалении фотографии 🤯',
      })
      .then(() => {
        refetchTask();
      });
  };

  return (
    <Box sx={{ flex: 1, mt: 1 }}>
      <>
        {namesArray.map((item, iter) => (
          <CleaningElement
            key={iter}
            stageName={item}
            stageNumber={(iter + 1).toString()}
            maxImageCount={maxImagesCountArray[iter]}
            afterCleaningPhotoArray={task.photoaftercleaning_set}
            pickedImage={pickedImage}
            setPickedImage={onPickImage}
            task={task}
            id={task.id}
            activeStep={activeStep}
            navigation={navigation}
            refetchTask={refetchTask}
            setImageLoading={setImageLoading}
          />
        ))}
      </>
      <Box sx={{ position: 'fixed', bottom: '75px', left: '27px' }}>
        <Fab
          component="span"
          onClick={() => {
            refetchNavigation();
            refetchTask();
          }}
          color="primary"
        >
          <LuRefreshCcw size={24} />
        </Fab>
      </Box>
      {pickedImage.length !== 0 && (
        <>
          <Box sx={{ position: 'fixed', bottom: '75px', left: 'calc(50% - 75px)' }}>
            <Fab
              component="span"
              onClick={() => {
                setPickedImage([]);
              }}
              color="primary"
            >
              <MdRemoveDone size={24} />
            </Fab>
          </Box>
          <Box sx={{ position: 'fixed', bottom: '75px', right: 'calc(50% - 75px)' }}>
            <Fab
              component="span"
              onClick={() => {
                handleDeleteImages();
              }}
              color="primary"
            >
              <LuTrash size={24} />
            </Fab>
          </Box>
        </>
      )}
    </Box>
  );
};

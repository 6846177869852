import React from 'react';
import { Box, createTheme, CssBaseline, Typography, useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { UI_URLS } from './config';
import { AuthComponent } from './features/auth/auth';
import { selectIsAuthenticated } from './features/auth/authSlice';
import HeaderComponent from './screens/header-component';
import UnauthorizedComponent from './screens/unauthorized-screen/unauthorized-screen';
import BottomNavigationComponent from './screens/authorized-screen/bottom_navigation-component';
import OverviewComponent from './screens/authorized-screen/overview-component';
import TasksComponent from './screens/authorized-screen/tasks-component';
import CreateTaskComponent from './screens/authorized-screen/create_task-component';
import { TTask, UnauthorizedScreenTypes } from './screens/types';
import { TaskComponent } from './screens/authorized-screen/task-component';
import { ToastContainer } from 'react-toastify';
import { Offline } from 'react-detect-offline';

export const App = () => {
  const [badgeCount, setBadgeCount] = React.useState(0);
  const [taskInformation, setTaskInformation] = React.useState<TTask>();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          // mode: 'dark',
          primary: {
            main: '#3a9561',
          },
          // secondary: {
          //   main: '#f50057',
          // },
          background: {
            // default: '#333333',
          },
        },
        typography: {
          fontFamily: 'Nunito',
        },
      }),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />
        <ToastContainer
          position="top-center"
          autoClose={2000}
          limit={5}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          pauseOnFocusLoss={false}
          pauseOnHover={false}
          draggable
          theme={'colored'}
        />
        <AuthComponent>
          <Box
            sx={{
              display: 'flex',
              minHeight: '100dvh',
              flexDirection: 'column',
            }}
          >
            <Offline>
              <Box sx={{ background: theme.palette.error.main }}>
                <Typography color="white" sx={{ textAlign: 'center' }}>
                  Отсутствует подключение к интернету
                </Typography>
              </Box>
            </Offline>
            {isAuthenticated ? (
              // Приватные пути для сайта
              <>
                <HeaderComponent type={'authorized'} taskInformation={taskInformation} />
                <Routes>
                  <Route path={UI_URLS.OVERVIEW_LINK} element={<OverviewComponent />} />
                  <Route path={UI_URLS.TASKS_LINK} element={<TasksComponent setBadge={setBadgeCount} />} />
                  <Route path={UI_URLS.TASKS_CREATE_LINK} element={<CreateTaskComponent />} />
                  <Route path={UI_URLS.TASK_LINK} element={<TaskComponent setInfo={setTaskInformation} />} />
                  <Route
                    path={UI_URLS.FAQ_LINK}
                    element={<UnauthorizedComponent type={UnauthorizedScreenTypes.FAQ_SCREEN} />}
                  />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
                <BottomNavigationComponent count={badgeCount} />
              </>
            ) : (
              // Общедоступные пути для сайта
              <>
                <HeaderComponent type={'unauthorized'} />
                <Routes>
                  <Route
                    path={UI_URLS.WELCOME_LINK}
                    element={<UnauthorizedComponent type={UnauthorizedScreenTypes.WELCOME_SCREEN} />}
                  />
                  <Route
                    path={UI_URLS.SIGNIN_LINK}
                    element={<UnauthorizedComponent type={UnauthorizedScreenTypes.SIGNIN_SCREEN} />}
                  />
                  <Route
                    path={UI_URLS.SIGNUP_LINK}
                    element={<UnauthorizedComponent type={UnauthorizedScreenTypes.SIGNUP_SCREEN} />}
                  />
                  <Route
                    path={UI_URLS.RESET_PASSWORD_LINK}
                    element={<UnauthorizedComponent type={UnauthorizedScreenTypes.RESET_PASSWORD_SCREEN} />}
                  />
                  <Route
                    path={UI_URLS.RESET_PASSWORD_CONFIRM_LINK}
                    element={<UnauthorizedComponent type={UnauthorizedScreenTypes.RESET_PASSWORD_CONFIRM_SCREEN} />}
                  />
                  <Route
                    path={UI_URLS.POLICY_LINK}
                    element={<UnauthorizedComponent type={UnauthorizedScreenTypes.PRIVACY_POLICY_SCREEN} />}
                  />
                  <Route
                    path={UI_URLS.VERIFY_EMAIL_LINK}
                    element={<UnauthorizedComponent type={UnauthorizedScreenTypes.VERIFY_EMAIL_SCREEN} />}
                  />
                  <Route
                    path={UI_URLS.RESEND_EMAIL_LINK}
                    element={<UnauthorizedComponent type={UnauthorizedScreenTypes.RESEND_EMAIl_SCREEN} />}
                  />
                  <Route
                    path={UI_URLS.PRIVACY_POLICY_EN_LINK}
                    element={<UnauthorizedComponent type={UnauthorizedScreenTypes.PRIVACY_POLICY_EN_SCREEN} />}
                  />
                  <Route
                    path={UI_URLS.ACCOUNT_DELETE_LINK}
                    element={<UnauthorizedComponent type={UnauthorizedScreenTypes.ACCOUNT_DELETE_SCREEN} />}
                  />
                  <Route
                    path={UI_URLS.FAQ_LINK}
                    element={<UnauthorizedComponent type={UnauthorizedScreenTypes.FAQ_SCREEN} />}
                  />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </>
            )}
          </Box>
        </AuthComponent>
      </BrowserRouter>
    </ThemeProvider>
  );
};

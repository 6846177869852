import React, { useEffect } from 'react';
import { Autocomplete, Box, Button, Fab, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { LuRefreshCcw } from 'react-icons/lu';
import 'react-toastify/dist/ReactToastify.css';
import { IResponseNavigation, TTask } from '../../screens/types';
import { TireFittingWheelComponent } from './tire-fitting-wheel';

interface ITireFittingRequestBeforeInterface {
  id?: string;
  task: TTask;
  value: string | undefined;
  valueComment: string | undefined;
  activeStep: number;
  navigation: IResponseNavigation;
  refetchTask: () => {};
  refetchNavigation: () => {};
}

export const TireFittingRequestBeforeComponent: React.FC<ITireFittingRequestBeforeInterface> = ({
  id,
  task,
  value,
  valueComment,
  navigation,
  activeStep,
  refetchTask,
  refetchNavigation,
}) => {
  const [howWheelsMounted, setHowWheelsMounted] = React.useState<string | null>(null);
  const [numberDamagedWheels, setNumberDamagedWheels] = React.useState<number | null>(null);
  const [namesOfDamagedWheels, setNamesOfDamagedWheels] = React.useState<string[]>([]);

  const handleNamesOfDamagedWheels = (event: React.MouseEvent<HTMLElement>, newFormats: string[]) => {
    if (namesOfDamagedWheels.length < newFormats.length) {
      if (numberDamagedWheels !== null) {
        if (numberDamagedWheels > namesOfDamagedWheels.length) {
          setNamesOfDamagedWheels(newFormats);
        }
      }
    } else {
      setNamesOfDamagedWheels(newFormats);
    }
  };

  return (
    <Box sx={{ flex: 1, mt: 1, mr: 1, ml: 1 }}>
      <Autocomplete
        id="howWheelsMounted"
        size="small"
        fullWidth
        sx={{ marginBottom: 2 }}
        disableClearable={true}
        onChange={(event, newValue) => {
          setHowWheelsMounted(newValue?.id ?? null);
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        renderInput={(params) => <TextField {...params} label={'Как на машине установлена резина'} />}
        options={[
          { id: 'all_wheels_have_the_same_rubber', label: 'На всех колесах одна резина' },
          { id: 'front_and_read_are_different', label: 'Разная для передней и задней оси' },
          { id: 'everything_is_different', label: 'Все разные' },
        ]}
      />
      <Autocomplete
        id="numberDamagedWheels"
        size="small"
        fullWidth
        disabled={howWheelsMounted === null}
        sx={{ marginBottom: 2 }}
        disableClearable={true}
        onChange={(event, newValue) => {
          setNamesOfDamagedWheels([]);
          setNumberDamagedWheels(newValue?.id ?? null);
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        renderInput={(params) => <TextField {...params} label={'Количество поврежденных колес'} />}
        options={[
          { id: 1, label: '1' },
          { id: 2, label: '2' },
          { id: 3, label: '3' },
        ]}
      />
      <Typography sx={{ textAlign: 'center', marginBottom: 1 }}>Укажите поврежденные колеса</Typography>
      <ToggleButtonGroup
        value={namesOfDamagedWheels}
        onChange={handleNamesOfDamagedWheels}
        disabled={numberDamagedWheels === null}
        sx={{ width: '100%', marginBottom: 2, display: 'flex', justifyContent: 'center' }}
      >
        <ToggleButton value="front_left" sx={{ width: '24%' }}>
          <Typography sx={{ fontSize: 12 }}>
            Переднее<br></br>левое
          </Typography>
        </ToggleButton>
        <ToggleButton value="front_right" sx={{ width: '25%' }}>
          <Typography sx={{ fontSize: 12 }}>
            Переднее<br></br>правое
          </Typography>
        </ToggleButton>
        <ToggleButton value="rear_left" sx={{ width: '25%' }}>
          <Typography sx={{ fontSize: 12 }}>
            Заднее<br></br>левое
          </Typography>
        </ToggleButton>
        <ToggleButton value="rear_right" sx={{ width: '24%' }}>
          <Typography sx={{ fontSize: 12 }}>
            Заднее<br></br>правое
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
      {howWheelsMounted === 'all_wheels_have_the_same_rubber' && (
        <TireFittingWheelComponent disk_location="all" isLast={true} />
      )}
      {howWheelsMounted === 'front_and_read_are_different' && (
        <>
          {(namesOfDamagedWheels.includes('front_left') || namesOfDamagedWheels.includes('front_right')) && (
            <TireFittingWheelComponent disk_location="front_axle" isLast={false} />
          )}
          {(namesOfDamagedWheels.includes('rear_left') || namesOfDamagedWheels.includes('rear_right')) && (
            <TireFittingWheelComponent disk_location="rear_axle" isLast={true} />
          )}
        </>
      )}
      {howWheelsMounted === 'everything_is_different' && (
        <>
          {namesOfDamagedWheels.includes('front_left') && (
            <TireFittingWheelComponent disk_location="front_left" isLast={false} />
          )}
          {namesOfDamagedWheels.includes('front_right') && (
            <TireFittingWheelComponent disk_location="front_right" isLast={false} />
          )}
          {namesOfDamagedWheels.includes('rear_left') && (
            <TireFittingWheelComponent disk_location="rear_left" isLast={false} />
          )}
          {namesOfDamagedWheels.includes('rear_right') && (
            <TireFittingWheelComponent disk_location="rear_right" isLast={true} />
          )}
        </>
      )}
      <Box sx={{ position: 'fixed', bottom: '75px', left: '27px', zIndex: 10 }}>
        <Fab
          component="span"
          onClick={() => {
            refetchNavigation();
            refetchTask();
          }}
          color="primary"
        >
          <LuRefreshCcw size={24} />
        </Fab>
      </Box>
    </Box>
  );
};

import React from 'react';
import { Autocomplete, Box, Fab, TextField, Typography } from '@mui/material';
import { IOpenTask, IResponseNavigation } from '../../screens/types';
import { LuRefreshCcw, LuUploadCloud } from 'react-icons/lu';
import { toast } from 'react-toastify';
import { useMultipleUpdateTaskMutation } from '../../redux/services/tasks';

import 'react-toastify/dist/ReactToastify.css';

interface ITechniqueTireBeforeInformation {
  id?: string;
  task: IOpenTask;
  activeStep: number;
  navigation: IResponseNavigation;
  refetchTask: () => {};
  refetchNavigation: () => {};
}

export const TechniqueTireBeforeInformation: React.FC<ITechniqueTireBeforeInformation> = ({
  id,
  task,
  navigation,
  activeStep,
  refetchTask,
  refetchNavigation,
}) => {
  const [multipleTaskUpdate, taskMultipleUpdateResult] = useMultipleUpdateTaskMutation();
  const [mileageBeforeTask, setMileageBeforeTask] = React.useState(task.mileage_before_task);
  const [carCondition, setCarCondition] = React.useState(task.car_condition);
  const [carConditionComment, setCarConditionComment] = React.useState(task.car_condition_comment);

  const uploadDisabled = () => {
    if (taskMultipleUpdateResult.isLoading) {
      return true;
    }
    if (mileageBeforeTask <= 0 || Number.isNaN(mileageBeforeTask)) {
      return true;
    }
    if (task.direction !== 'impound_lot') {
      if (carCondition === '') {
        return true;
      }
      if (carCondition === 'comments' || carCondition === 'out_of_order') {
        if (carConditionComment === '' || carConditionComment === null) {
          return true;
        }
      }
    }
    return false;
  };

  const handleTaskUpdate = async () => {
    const updatedValues = {
      mileage_before_task: mileageBeforeTask.toString(),
      car_condition: carCondition,
      car_condition_comment: carConditionComment,
    };
    toast
      .promise(multipleTaskUpdate({ id: id, values: updatedValues }).unwrap(), {
        pending: 'Пожалуйста, подождите',
        success: 'Статусы обновлены 👌',
        error: 'Ошибка при обновлении статуса 🤯',
      })
      .then(() => {
        refetchTask();
      });
  };

  const getOptions = () => {
    return [
      { id: 'ok', label: 'Все ОК' },
      { id: 'comments', label: 'Есть замечания' },
      { id: 'out_of_order', label: 'Автомобиль неисправен' },
    ];
  };

  return (
    <Box sx={{ flex: 1, mt: 1, mr: 1, ml: 1 }}>
      <TextField
        label={'Пробег'}
        value={mileageBeforeTask === 0 ? '' : mileageBeforeTask}
        type={'number'}
        margin="normal"
        sx={{ mt: 0 }}
        fullWidth
        size="small"
        onChange={(e) => setMileageBeforeTask(parseFloat(e.target.value))}
      />
      {task.direction !== 'impound_lot' && (
        <>
          <Autocomplete
            id="car_condition_before"
            size="small"
            fullWidth
            disablePortal
            disableClearable
            options={getOptions()}
            renderInput={(params) => <TextField {...params} label="Состояние автомобиля" />}
            value={getOptions().find((item) => item.id == carCondition)}
            // isOptionEqualToValue={(option, value) => option.label === value.label}
            onChange={(event, newValue) => {
              setCarCondition(newValue.id);
            }}
          />
          {['comments', 'out_of_order'].includes(carCondition) && (
            <TextField
              label={'Комментарий'}
              value={carConditionComment}
              margin="normal"
              sx={{ mt: 1 }}
              fullWidth
              size="small"
              onChange={(e) => {
                setCarConditionComment(e.target.value);
              }}
            />
          )}
        </>
      )}
      <Box sx={{ position: 'fixed', bottom: '75px', left: '27px' }}>
        <Fab
          component="span"
          onClick={() => {
            refetchNavigation();
            refetchTask();
          }}
          color="primary"
        >
          <LuRefreshCcw size={24} />
        </Fab>
      </Box>
      <Box sx={{ position: 'fixed', bottom: '75px', right: '27px' }}>
        <Fab
          component="span"
          color="primary"
          disabled={uploadDisabled()}
          onClick={() => {
            handleTaskUpdate();
          }}
        >
          <LuUploadCloud size={24} />
        </Fab>
      </Box>
    </Box>
  );
};

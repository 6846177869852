import React from 'react';
import { Autocomplete, Box, Fab, TextField } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { IOpenTask, IResponseNavigation, TTask } from '../../screens/types';
import { LuRefreshCcw, LuUploadCloud } from 'react-icons/lu';
import { toast } from 'react-toastify';
import { useMultipleUpdateTaskMutation } from '../../redux/services/tasks';

interface ITechniqueTireAfterInformation {
  id?: string;
  task: IOpenTask;
  activeStep: number;
  navigation: IResponseNavigation;
  refetchTask: () => {};
  refetchNavigation: () => {};
}

export const TechniqueTireAfterInformation: React.FC<ITechniqueTireAfterInformation> = ({
  id,
  task,
  navigation,
  activeStep,
  refetchTask,
  refetchNavigation,
}) => {
  const [multipleTaskUpdate, taskMultipleUpdateResult] = useMultipleUpdateTaskMutation();
  const [mileageAfterTask, setMileageAfterTask] = React.useState(task.mileage_after_task);
  const [carConditionAfter, setCarConditionAfter] = React.useState(task.car_condition_after);
  const [carConditionCommentAfter, setCarConditionCommentAfter] = React.useState(task.car_condition_comment_after);
  const [locationKeys, setLocationKeys] = React.useState(task.location_keys);

  const uploadDisabled = () => {
    if (taskMultipleUpdateResult.isLoading) {
      return true;
    }
    if (mileageAfterTask <= 0 || Number.isNaN(mileageAfterTask)) {
      return true;
    }
    if (task.direction !== 'impound_lot') {
      if (carConditionAfter === '') {
        return true;
      }
      if (carConditionAfter === 'comments' || carConditionAfter === 'out_of_order') {
        if (carConditionCommentAfter === '' || carConditionCommentAfter === null) {
          return true;
        }
      }
    }
    if (task.direction === 'tire_fitting' || task.direction === 'washer_fluid') {
      if (locationKeys === '') {
        return true;
      }
    }
    return false;
  };

  const handleTaskUpdate = async () => {
    const updatedValues = {
      mileage_after_task: mileageAfterTask.toString(),
      car_condition_after: carConditionAfter,
      car_condition_comment_after: carConditionCommentAfter,
      location_keys: locationKeys,
    };

    toast
      .promise(multipleTaskUpdate({ id: id, values: updatedValues }).unwrap(), {
        pending: 'Пожалуйста, подождите',
        success: 'Статусы обновлены 👌',
        error: 'Ошибка при обновлении статуса 🤯',
      })
      .then(() => {
        refetchTask();
      });
  };

  const getOptions = (type: 'carCondition' | 'locationKeys') => {
    if (type === 'carCondition') {
      return [
        { id: 'ok', label: 'Все ОК' },
        { id: 'comments', label: 'Есть замечания' },
        { id: 'out_of_order', label: 'Автомобиль неисправен' },
      ];
    } else if (type === 'locationKeys') {
      return [
        { id: 'passed_to_replacement', label: 'Передал сменщику' },
        { id: 'remained_with_me', label: 'Остались у меня' },
        { id: 'hidden', label: 'Спрятаны' },
      ];
    } else {
      return [];
    }
  };

  return (
    <Box sx={{ flex: 1, mt: 1, mr: 1, ml: 1 }}>
      <TextField
        label={'Пробег'}
        value={mileageAfterTask === 0 ? '' : mileageAfterTask}
        type={'number'}
        margin="normal"
        sx={{ mt: 0 }}
        fullWidth
        size="small"
        onChange={(e) => setMileageAfterTask(parseFloat(e.target.value))}
      />
      {task.direction !== 'impound_lot' && (
        <>
          <Autocomplete
            id="car_condition_after"
            size="small"
            fullWidth
            disablePortal
            disableClearable
            options={getOptions('carCondition')}
            renderInput={(params) => <TextField {...params} label={'Состояние автомобиля (После)'} />}
            value={getOptions('carCondition').find((item) => item.id == carConditionAfter)}
            onChange={(event, newValue) => {
              setCarConditionAfter(newValue.id);
            }}
          />
          {['comments', 'out_of_order'].includes(carConditionAfter) && (
            <TextField
              label={'Комментарий'}
              value={carConditionCommentAfter}
              margin="normal"
              sx={{ mt: 1 }}
              fullWidth
              size="small"
              onChange={(e) => {
                setCarConditionCommentAfter(e.target.value);
              }}
            />
          )}
        </>
      )}
      {(task.direction === 'tire_fitting' || task.direction === 'washer_fluid') && (
        <Autocomplete
          id="location_keys"
          size="small"
          fullWidth
          disablePortal
          disableClearable
          sx={{ mt: 1 }}
          options={getOptions('locationKeys')}
          renderInput={(params) => <TextField {...params} label={'Местоположение ключей'} />}
          value={getOptions('locationKeys').find((item) => item.id == locationKeys)}
          onChange={(event, newValue) => {
            setLocationKeys(newValue.id);
          }}
        />
      )}
      <Box sx={{ position: 'fixed', bottom: '75px', left: '27px' }}>
        <Fab
          component="span"
          onClick={() => {
            refetchNavigation();
            refetchTask();
          }}
          color="primary"
        >
          <LuRefreshCcw size={24} />
        </Fab>
      </Box>
      <Box sx={{ position: 'fixed', bottom: '75px', right: '27px' }}>
        <Fab
          component="span"
          color="primary"
          disabled={uploadDisabled()}
          onClick={() => {
            handleTaskUpdate();
          }}
        >
          <LuUploadCloud size={24} />
        </Fab>
      </Box>
    </Box>
  );
};
